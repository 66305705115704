@tailwind base;
@tailwind components;
@tailwind utilities;

.article-content a {
    @apply text-white underline; /* リンクを白色にし、下線を追加 */
  }

.article-content strong,
.article-content li {
  @apply text-white;
}